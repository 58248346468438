<template>
  <div>
    <sign-in-component />
  </div>
</template>

<script>
import SignInComponent from "@/components/gate/SignInComponent";
export default {
  name: "LoginPage",
  components: { SignInComponent },
  created() {},
  methods: {}
};
</script>
